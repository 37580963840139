import { setterDate } from '@/plugins/utils';

export interface InvoiceModelImpl {
  _id: string;
  our_company_name: string;
  our_company_street: string;
  our_company_city: string;
  our_company_vat_number: string;

  invoice_payment_getaway: string;
  invoice_type: string;
  invoice_date: Date;
  user_id: string;
  payment_id: string;
  creditcard_id: string;
  invoice_numeration: string;
  invoice_client_full_name: string;
  invoice_client_country: string;
  invoice_client_city: string;
  invoice_client_address: string;
  invoice_client_vat_number: string;
  invoice_product_name: string;
  invoice_price: number;
  invoice_price_tax: number;
  invoice_price_without_tax: number;
  invoice_price_percent_applied_tax: number;
  invoice_price_currency: string;
  invoice_price_currency_sign: string;
  created_at: Date;
  updated_at: Date;
}

export class InvoiceModelDTO implements InvoiceModelImpl {
  _id!: string;
  our_company_name!: string;
  our_company_street!: string;
  our_company_city!: string;
  our_company_vat_number!: string;
  invoice_payment_getaway!: string;
  invoice_type!: string;
  invoice_date!: Date;
  user_id!: string;
  payment_id!: string;
  creditcard_id!: string;
  invoice_numeration!: string;
  invoice_client_full_name!: string;
  invoice_client_country!: string;
  invoice_client_city!: string;
  invoice_client_address!: string;
  invoice_client_vat_number!: string;
  invoice_product_name!: string;
  invoice_price!: number;
  invoice_price_tax!: number;
  invoice_price_without_tax!: number;
  invoice_price_percent_applied_tax!: number;
  invoice_price_currency!: string;
  invoice_price_currency_sign!: string;
  created_at!: Date;
  updated_at!: Date;
}

export default class InvoiceModel extends InvoiceModelDTO {
  constructor(dto: InvoiceModelDTO) {
    super();
    Object.assign(this, dto);
  }

  getInvoicePrice = () => this.generatePriceFormat(this.invoice_price.toFixed(2));
  getInvoicePriceTax = () => this.generatePriceFormat(this.invoice_price_tax.toFixed(2));
  getInvoicePriceWithoutTax = () => this.generatePriceFormat(this.invoice_price_without_tax.toFixed(2));
  getInvoiceDate = () => setterDate(this.invoice_date);

  private generatePriceFormat = (amount: string) => amount.replace(/\d(?=(\d{3})+\.)/g, '$&,');
}
