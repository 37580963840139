import API from '../repository';
import InvoiceModel, { InvoiceModelDTO } from '@/models/Invoice.model';

const RESOURCE = '/invoices';

export default {
  async getFullDataInvoice(invoice_id: string) {
    const response: InvoiceModelDTO = await API.get(`${RESOURCE}/${invoice_id}`);
    return new InvoiceModel(response);
  },

  async downloadAllInvoicesAndClients(month: number, year: number) {
    await API.get(`${RESOURCE}/download`, {
      params: {
        month,
        year,
      },
      responseType: 'blob',
    }).then(data => {
      const downloadUrl = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `${year}-${month}-invoices.zip`); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  },

  async downloadInvoice(invoice_id: string, nameFile: string) {
    API.get(`${RESOURCE}/${invoice_id}/download`, { responseType: 'blob' }).then(data => {
      const downloadUrl = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `${nameFile}.pdf`); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  },
};
